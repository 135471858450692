<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :clearable="clearable"
          v-model="dateFormated"
          :placeholder="placeholder || ''"
          :error-messages="errors"
          :disabled="disabled"
          :dark="darkEnabled"
          :label="label"
          :dense="dense"
          :solo="solo"
          :hint="hint"
          outlined
          rounded
          flat
          type="text"
          v-on="readonly ? null : on"
          @blur="onBlur"
          @click:append="visibled = !visibled"
          :readonly="readonly"
          v-mask="'##/##/####'"
        ></v-text-field>
      </template>
      <v-date-picker
        no-title
        v-model="model"
        @input="menu = false"
        :min="min"
        :max="max"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import * as moment from 'moment'
export default {
  name: 'FormDatePicker',
  props: {
    type: {
      type: String,
      default: 'text',
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    formatter: {
      type: String,
      default: 'DD/MM/YYYY',
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  directives: {
    mask
  },
  data: () => ({
    visibled: false,
    model: '',
    touched: false,
    menu: false,
    dateFormated: ''
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    },
    model: {
      handler(newVal) {
        this.$emit('change', this.model)
        this.$emit('input', this.model)
        this.checkValidation()
        this.dateFormated = this.formatDate(newVal)
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (!this.validation.required) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        return errorsMess
      }
      return []
    }
  },
  methods: {
    onBlur(event) {
      this.model = event.target.value
      this.$emit('blur', this.model)
      if (this.dateFormated) {
        const [day, month, year] = this.dateFormated.split('/')
        let parsedDate = `${year}-${month && month.padStart(2, '0')}-${day &&
          day.padStart(2, '0')}`
        if (moment(parsedDate).isValid()) {
          this.model = parsedDate
        }
      }
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    },
    formatDate(date) {
      return date ? moment(date).format(this.formatter) : ''
    }
  }
}
</script>
